body {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
  font-family: 'Roboto', sans-serif;
  background-color: #f0efef;
}

.font-12 {
  font-size: 12px;
}

/* .header-container {
  position: fixed;
  top: 50;
  left: 0;
  width: 100%;
} */

.activepageforprivethnav {
  background-color: white;
  color: #103a40 !important;
  border-radius: 5px !important;
  font-weight: 600 !important;
}

.lineSolid {
  overflow: hidden;
  text-align: center;
}

.lineSolid:before,
.lineSolid:after {
  background-color: #000;
  content: "";
  display: inline-block;
  height: 1px;
  position: relative;
  vertical-align: middle;
  width: 20%;
}

.lineSolid:before {
  right: 0.5em;
  margin-left: -50%;
}

.lineSolid:after {
  left: 0.5em;
  margin-right: -50%;
}


input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0
}

.phone_input {
  border-bottom: 1.5px solid gray;
}

.phone_input input {
  border: none;
  background-color: transparent;
}

.phone_input input:focus {
  border: none !important;
  outline: none !important;
}

.PhoneInputCountryIcon--border {
  background-color: transparent !important;
  box-shadow: none !important;
}

/*  mui table sort icon and lable color chnage */

/* .css-1qgma8u-MuiButtonBase-root-MuiTableSortLabel-root.Mui-active .MuiTableSortLabel-icon {
  opacity: 1;
  color: #fff !important;
}

.css-1qgma8u-MuiButtonBase-root-MuiTableSortLabel-root.Mui-active {
  color: #fff !important;
}

.css-1qgma8u-MuiButtonBase-root-MuiTableSortLabel-root:hover {
  color: #fff !important;
} */

/* multiple input */
.rti--container {
  padding: 0 !important;
  border-left: 0px !important;
  border-top: 0px !important;
  border-right: 0px !important;
  background-color: transparent !important;
  border-radius: 0px !important;
  /* border-bottom: 1px solid #757575 !important; */
}

.rti--container:focus-within {
  box-shadow: 0px 1.5px 0px rgb(8, 8, 121) !important;
  border-bottom: none !important;
  /* box-shadow: 0px 0px 0px black !important */
}

.rti--input {
  background-color: transparent !important;
}

.rti--tag {
  background-color: #e0e0e0 !important;
  border-radius: 50px !important;
}

.cursor {
  cursor: pointer;
}

/*  */

.dropdown .dropdown-menu .dropdown-item {
  cursor: pointer !important;
}


.Image_profile {
  width: 240px;
  height: 240px;
  border: 6px solid #103a40;
  position: relative;
  /* background-image: url(./assets//images/profile.jpg); */
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  border-radius: 50%;
  /* box-shadow: 0 0 0 3px #e78267; */

}

.Image_profile .profile_img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  object-fit: cover;

}

.Image_profile .profile_icon {
  position: absolute;
  bottom: 20px;
  right: 5px;
  cursor: pointer;
}

.image_show_profile {
  width: 200px !important;
  height: 200px !important;
  border-radius: 50% !important;
  border: 8px solid #fff;
}

.image_show_profile img {
  width: 100% !important;
  height: 100% !important;
  border-radius: 50% !important;
}

.css-1h9z7r5-MuiButtonBase-root-MuiTab-root.Mui-selected {
  color: #1976d2 !important;
}


/* .css-levciy-MuiTablePagination-displayedRows {
  margin: 0 !important;
} */

/* .css-pdct74-MuiTablePagination-selectLabel {
  margin: 0 !important;
} */

/* .css-ptiqhd-MuiSvgIcon-root {
  color: gray !important;
  font-size: 30px !important;
} */


h3 {
  font-size: 14px;
  margin: 0 !important;
}

li {
  font-size: 14px;
}

.noWarp {
  white-space: nowrap !important;
}

.col-2 {
  width: 12.5%;
}

.activepage {
  background-color: white;
  padding: 5px 20px 5px 20px;
  color: #103a40 !important;
  border-radius: 5px;
  font-weight: bold;
}

.btn:focus {
  outline: 0;
  box-shadow: none;
}

.bordermain {
  border: 2px solid #0a5bfe10;
  border-radius: 10px;
}

/* navbar start  */

.dashboardlist li {
  list-style: none;
  margin: 10px 0 -7px 0;
}

.dashboardlist a {
  text-decoration: none;
  color: white;
  padding: 8px 20px 8px 20px;
}

.dashboardlist a:hover {
  background-color: white;
  color: #103a40;
  border-radius: 5px;
  font-weight: bold;
}

/* navbar end  */

/* button 1 start */
#rectangle {
  width: 120px;
  height: 46px;
  background: rgb(255, 255, 255);
}

#rectangle1 {
  width: 130px;
  height: 46px;
  background: #103a40;
}

#pentagon {
  width: 0;
  height: 0;
  border-top: 23px solid transparent;
  border-left: 40px solid rgb(255, 255, 255);
  border-bottom: 23px solid transparent;
  z-index: 100;
}

#pentagon1 {
  width: 0;
  height: 0;
  border-top: 23px solid transparent;
  border-left: 40px solid #f0efef;
  border-bottom: 23px solid transparent;
}

#pentagon2 {
  width: 0;
  height: 0;
  border-top: 23px solid transparent;
  border-left: 40px solid #103a40;
  border-bottom: 23px solid transparent;
}

/* button 1 end */

/* button 2 start */

.buttondiv {
  padding: 10px 10px;
  background-color: #2F90EA;
  color: white;
  font-size: 14px;
}

.content {
  background-color: #ffff !important;
}

.form-control,
.form-select:focus {
  color: #212529 !important;
  background-color: #fff !important;
  /* border-color: #86b7fe; */
  outline: 0;
  box-shadow: none !important;
}

/* button 2 end */

/* list data start */
tbody,
td,
tfoot,
th,
thead,
tr {
  border-color: inherit;
  border-style: solid;
  border-width: 0;
  font-weight: 100;
}

table {
  background-color: white;
  border-collapse: collapse;
  width: 200%;
  overflow-x: auto;
}

th {
  background-color: #103a40;
  color: white;
  font-size: 14px;
  padding: 10px 0 5px 0;
  font-weight: 100 !important;
}

tr {
  font-size: 12px !important;
  margin: 30px !important;
}

.tr_border {
  border-bottom: 1px solid #ddd;
}

tr,
td {
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 4px;
  padding-right: 6px;
}

.tableclinics tr,
td {
  padding-top: 15px;
  padding-bottom: 15px;
  padding-left: 10px;
  padding-right: 10px;
}

.managewidth {
  width: 98%;
  margin: auto;
  overflow: auto;
}

/* list data end */

/* pagination start */
.center {
  text-align: center;
}

.pagination {
  display: inline-block;
}

.pagination a {
  color: black;
  font-size: 12px;
  float: left;
  padding: 2px 2px;
  text-decoration: none;
  transition: background-color .3s;
  margin: 0 10px;
}

.pagination a.active {
  background-color: #103a40;
  color: white;
  border: 1px solid #103a40;
}

.pagination a:hover:not(.active) {
  background-color: #ddd;
}

/* pagination end */

.hospital_list_color {
  color: #2F90EA;
  font-weight: bold !important;
}

#rectangle_green {
  width: 120px;
  height: 46px;
  background: #103a40;
  color: white;
}

#pentagon_green {
  width: 0;
  height: 0;
  border-top: 23px solid transparent;
  border-left: 40px solid #103a40;
  border-bottom: 23px solid transparent;
  z-index: 100;
}

/* form start */
.collapsible {
  width: 100%;
  height: auto;
  outline: none;
  border: 1px solid #ced4da;
  background-color: #ffffff;
  border-radius: 5px;
}

.collapsible:hover {
  background-color: #ffffff;
}

.content {
  padding: 0 18px;
  display: none;
  overflow: hidden;
  background-color: #f1f1f1;
}



.searchbtn {
  background-color: #2F90EA;
  color: white;
  padding: 8px 40px;
  border: 2px solid #2F90EA;
  border-radius: 5px;
}

::placeholder {
  font-size: 12px;
}

.radiobtn1 {
  margin-left: 10px;
}

.radiobtn2 {
  margin-left: 25px;
}

.radiobtn3 {
  margin-left: 15px;
}

.mainradiobtn {
  height: 16px !important;
  width: 16px !important;
}

label {
  font-size: 14px;
}

select {
  font-size: 14px !important;
}

option {
  font-size: 12px;
}

input {
  font-size: 12px;
}

/* form end */
/* toggle button start */
.switch {
  position: relative;
  display: inline-block;
  width: 35px;
  height: 14px;
  margin-top: 5px;
}

.switch .input1 {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 16px;
  width: 16px;
  left: -1px;
  bottom: -1px;
  background-color: white;
  box-shadow: 0px 0px 2px;
  -webkit-transition: .4s;
  transition: .4s;
}

.input1:defaultChecked+.slider {
  background-color: #45BE67;
}

.input1:focus+.slider {
  box-shadow: 0 0 1px #45BE67;
}

.input1:defaultChecked+.slider:before {
  -webkit-transform: translateX(22px);
  -ms-transform: translateX(22px);
  transform: translateX(22px);
}

.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

/* toggle button end */

.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
  /* background-color: none; */
  border-color: none;
  border: none;
  border-bottom: 5px solid #0072ff;
}

.nav-tabs .nav-link:focus,
.nav-tabs .nav-link:hover {
  border-color: none;
  isolation: isolate;
}

.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
  color: #0072ff !important;
  /* background-color: #fff; */
  border-color: none !important;
}

/* setting */
.margin_setting {
  margin-top: 8px;
  margin-bottom: -10px;
}

.setting_hover {
  color: black !important;
}

.dropbtnsetting {
  background-color: #ffffff;
  color: black;
  /* padding: 11px; */
  font-size: 16px;
  border: none;
  margin-left: 16px;
}

.dropdownsetting {
  position: relative;
  display: inline-block;
}

.dropdownsetting-content {
  display: none;
  position: absolute;
  top: 1px;
  left: -162px;
  background-color: #f1f1f1;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

.dropdownsetting-content a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

.dropdownsetting-content a:hover {
  background-color: #ddd;
}

.dropdownsetting:hover .dropdownsetting-content {
  display: block;
}

.dropdownsetting:hover .dropbtnsetting {
  background-color: #ffffff;
}

/* setting */



@media screen and (min-width: 1200px) and (max-width: 1560px) {
  .radiobtn1 {
    margin-left: 5px;
  }

  .radiobtn2 {
    margin-left: 15px;
  }

}

@media screen and (min-width: 768px) and (max-width: 1200px) {


  h3 {
    font-size: 14px;
  }

  li {
    font-size: 14px;
  }

  /* navbar start  */
  .dashboardlist li {
    list-style: none;
    padding: 5px 10px 5px 10px;
    color: white;
    margin: 6px 0 -10px 0;
  }

  .dashboardlist li:hover {
    background-color: white;
    color: #103a40;
    border-radius: 5px;
  }



  /* navbar end  */

  /* button 1 start */
  #rectangle {
    width: 120px;
    height: 46px;
    background: rgb(255, 255, 255);
  }

  #rectangle1 {
    width: 100px;
    height: 46px;
    background: #103a40;
  }

  #pentagon {
    width: 0;
    height: 0;
    border-top: 23px solid transparent;
    border-left: 40px solid rgb(255, 255, 255);
    border-bottom: 23px solid transparent;
    z-index: 100;
  }

  #pentagon1 {
    width: 0;
    height: 0;
    border-top: 23px solid transparent;
    border-left: 40px solid #F5F5F5;
    border-bottom: 23px solid transparent;
    z-index: -1;
  }

  #pentagon2 {
    width: 0;
    height: 0;
    border-top: 23px solid transparent;
    border-left: 40px solid #103a40;
    border-bottom: 23px solid transparent;
  }

  /* button 1 end */

  /* button 2 start */

  .buttondiv {
    padding: 10px 10px;
    background-color: #2F90EA;
    color: white;
    font-size: 12px;
  }

  /* button 2 end */

  /* list data start */
  tbody,
  td,
  tfoot,
  th,
  thead,
  tr {
    border-color: inherit;
    border-style: solid;
    border-width: 0;
    font-weight: 100;
  }

  table {
    border-collapse: collapse;
    width: 500%;
    overflow-x: auto;
  }

  th {
    background-color: #103a40;
    color: white;
    font-size: 14px;
    font-weight: 100 !important;
  }

  tr {
    font-size: 12px !important;
    margin: 30px !important;
  }

  .tr_border {
    border-bottom: 1px solid #ddd;
  }

  tr,
  td {
    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 6px;
    padding-right: 6px;
  }

  /* list data end */

  /* pagination start */
  .center {
    text-align: center;
  }

  .pagination {
    display: inline-block;
  }

  .pagination a {
    color: black;
    font-size: 12px;
    float: left;
    padding: 2px 2px;
    text-decoration: none;
    transition: background-color .3s;
    margin: 0 10px;
  }

  .pagination a.active {
    background-color: #4CAF50;
    color: white;
    border: 1px solid #4CAF50;
  }

  .pagination a:hover:not(.active) {
    background-color: #ddd;
  }

  /* pagination end */

  /* form start */

  .radiobtn1 {
    margin-left: 5px;
  }

  .radiobtn2 {
    margin-left: 10px;
  }


  /* form end */

}

.breadcrumb-title-box-w {
  background: #fff;
  padding: 0 15px;
  width: max-content;
  height: 46px;
  color: #103a40;
}

.breadcrumb-title-box-d {
  background: #103a40;
  padding: 0 15px 0 0;
  width: max-content;
  height: 46px;
  color: #ffff;
  cursor: pointer;
}

.err {
  font-size: small;
  color: red;
}




/* Anurag css */
.provider_Skills_Qualifications_section ul {
  display: flex;
  flex-wrap: wrap;
}

.provider_Skills_Qualifications_section ul li {
  list-style: disc;
  margin-right: 50px;
  margin-top: 10px;
  font-size: 15px;
  color: gray;
  font-weight: 500;

}

/* phone Input Style Start */

.react-tel-input .form-control {
  background: #f0efef !important;
  width: 208px !important;
  border-bottom: 1px solid rgb(82 82 82 / 69%) !important;
  border-top: 0px !important;
  border-right: 0 !important;
  border-radius: 0px !important;
}

.react-tel-input .flag-dropdown {
  border-bottom: 1px solid rgb(82 82 82 / 69%) !important;
  border-top: 0 !important;
  border-right: 0 !important;
  border-left: 0 !important;
  border-radius: 0 !important;
  background: #f0efef !important;
}

.react-tel-input .form-control:hover {
  border-bottom: 2px solid #000 !important;
}

.react-tel-input .form-control:focus {
  border-bottom: 2px solid #2370e0 !important;
}

.react-tel-input .selected-flag:hover,
.react-tel-input .selected-flag:focus {
  background-color: #f0efef !important;
  border-bottom: 2px solid #000;
  border-radius: 0;
}

.modal_image_section {
  height: 300px;
  width: 100%;
}

.modal_image_section img {
  width: 100%;
  height: 100%;

}

/* Availability */

.Availability_container {}

.cus-days {
  padding: 0;

}

.cus-days li {
  list-style: none;
  margin-top: 10px;
  display: flex;
  justify-content: center;
  min-height: 60px;
}

.cus-time-list {
  padding: 0;
}

.cus-time-list li {
  list-style: none;
  margin-top: 10px;
  max-width: 240px;
  background-color: #9dbfe2;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 68px;
  padding: 2px 0px;
}

.cus-time-list li p {
  margin-bottom: 1px;
}

.availability_date {
  max-height: 40px;
  /* flex-direction: column; */
}

.availability_date p {
  margin-bottom: 0;
}

/* .availability_date i {
  display: none;
  cursor: pointer;
}

.hover_div:hover .availability_date i {
  display: block !important;
} */
.hover_div {
  width: 270px !important;
  /* width: 300px !important; */
}

.hover_div ::-webkit-scrollbar {
  /* width: 100%; */
}

.hover_div ::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  background-color: #F5F5F5;
}

.hover_div ::-webkit-scrollbar {
  width: 12px;
  height: 7px;
  background-color: #F5F5F5;
}

.hover_div ::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
  background-color: gray;
}


.availability_show_location {
  overflow-x: auto;
}

.availability_show_location p {
  white-space: nowrap;
}

.Unavailability_container {
  min-height: 50vh;
}

.Unavailability_card {
  border-radius: 20px;
  background-color: #ecf1f7;
  min-height: 260px;
}

.add_unavailability .form {
  border: 1px solid gray;
  border-radius: 5px;
  padding: 10px;
}

.add_unavailability h2 {
  color: #1976d2 !important;
}

.Unavailability_card i {
  color: rgb(192, 9, 9);
  cursor: pointer;
}

.Unavailability_card hr {
  color: gray;
}

.Unavailability_card p {
  color: rgb(73, 72, 72);
  margin-bottom: 0;
  font-size: 18px;
}

.Unavailability_card span {
  font-size: 15px;
  color: gray;
}

.Unavailability_card ul {
  padding: 0;
}

.Unavailability_card ul li {
  list-style: none;
  font-size: 14px;
  color: gray;
}

.unavailability_time {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
}

.add_button {
  padding: 0px !important;
  height: 30px !important;
  margin-left: 5px;
  min-width: 30px !important;
  border-radius: 50% !important;
}

/*  add availability */
.add_availability_main_box {
  height: 85vh;
  background-color: #fff;
  margin-left: 70px;
  margin-right: 70px;
  margin-top: 10px;
  padding: 10px 50px 10px 50px !important;
  border-radius: 10px;
  overflow-y: auto;
  /* position: relative; */

}

.add_availability_main_box h2 {
  color: #1976d2 !important;
}

.Availability_day {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

.Availability_day p {
  margin-top: 0px;
  /* margin-right: 50px; */
  color: rgb(73, 73, 73);
  font-size: 17px;
}

.Availability_day .check_box {
  margin-top: -7px !important;
}

/* adVailability_check_box  start*/

.adVailability_check_box {
  /* border: 2px solid black; */
  min-height: 300px;

}

.adVailability_check_box .span {
  font-size: 20px;
  color: gray;
}

.adVailability_check_box ul {
  padding: 0;
}

.adVailability_check_box ul li {
  list-style: none;
  margin-bottom: -10px;
}

/* article slot */
article {
  position: relative;
  width: 190px;
  height: 50px;
  margin: 5px 22px;
  float: left;
  background-color: #fff;
  color: gray;
  box-sizing: border-box;
}

article div {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  line-height: 25px;
  transition: .2s ease;
}

article input {
  position: absolute;
  top: 0;
  left: 0;
  width: 140px;
  height: 100px;
  opacity: 0;
  cursor: pointer;
}

.feature1 input[type=checkbox]:checked~div {
  border: 2px solid #297eb3;
  background-color: #297eb31f;
}

.feature1 input[type="checkbox"]:not(:checked)~div {
  border: 2px solid gray;
}

/*  */


/* single slot Css */
.slot_Active {
  background-color: #fff;
  color: gray;
  border: 2px solid gray;
  border-radius: 7px;
}

.slot_booked {
  background-color: rgba(244, 247, 246, 0.945);
  color: rgba(69, 135, 145, 0.747);
  border: 2px solid gray;
  border-radius: 7px;
}

.slot_Active span {
  color: black !important;
}

.slot_InActive {
  background: #297eb3;
  /* color: #0072ff; */
  border: 2px solid #297eb3;
  border-radius: 7px;

}

.slot_InActive span {
  color: #fff !important;
  margin-top: 10px;
}

.article_show_status {
  position: relative;
}

.article_show_status p {
  position: absolute;
  top: -5px;
  color: #dddbdb;
  font-size: 14px;
}

.slotOverflow {
  height: 325px;
  overflow-y: auto;
}

#Booked_slot {
  background: #6e91a7;
  /* color: #0072ff; */
  border: 2px solid #6e91a7;
  border-radius: 7px;
}


.schedule_img {
  width: 22px;
  height: 22px;
  margin-right: 8px;
  cursor: pointer;
}

@keyframes slidein {
  from {
    margin-top: 100%;
    width: 300%;
  }

  to {
    margin: 0%;
    width: 100%;
  }
}

/* adVailability_check_box end*/





/* phone Input Style end */



/* scheduler_box */
.scheduler_box {
  background-color: #fff;
  height: 100%;
}



/* TemplateLibrary_main_box */


.TemplateLibrary_main_box {
  /* height: 80vh !important; */
  padding: 10px 90px !important;
  height: 85vh;
  overflow-y: auto;
}

.TemplateLibrary_child_box {
  height: 65%;
  border-radius: 20px;
  background-color: #fff !important;
  padding: 30px 90px !important;
  overflow-y: auto;


}

.TemplateLibrary_child_box .about_form {
  border: 2px solid #103a40;
  border-radius: 10px;
  padding: 40px 20px;
}

.about_form_dynamic {
  border: 2px solid #103a40;
  border-radius: 10px;
  padding: 40px 20px;
}

.import_form {
  height: 50vh;
  border: 2px solid #103a40;
  border-radius: 10px;
  padding: 5px;

}

.category_list {
  border-right: 2px solid #103a40;
  height: 100%;
  text-align: start;
}

.all_List_By_category_Id {
  height: 100%;
  overflow-y: auto;
}

.category_list .select_category.inactive {
  border: 2px solid gray;
  color: gray;
  cursor: pointer;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 10px;
  border-radius: 20px;
}

.category_list .select_category.active {
  border: 2px solid #297eb3;
  background-color: #297eb31f;
  color: #297eb3;
  cursor: pointer;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 10px;
  border-radius: 20px;
}

.category_list .select_category p {
  margin-bottom: 0;
}

.import_view_form {
  overflow-y: auto;
}


.radiobtn1 {
  margin-left: 5px;
  width: 16px;
}

.radiobtn2 {
  margin-left: 10px;
  width: 16px;
}

a.active {

  background-color: #ffffff;
  padding: 8px 5px 8px 5px;
  color: #103a40 !important;
  border-radius: 5px;
  font-weight: 600;
}

/* box style */

/* .clinic_hover_box{
  position: absolute;
  top: 0;
  right: 0;
  width: 150px;
  height: 250px;
  border: 2px solid blanchedalmond;
  display: none;
}
#hover_box .clinic_hover_box:hover{
  display: block !important;
} */

.setting_dropdown>ul li a {
  color: #000 !important;
}


.setting_dropdown:hover>ul.dropdown-menu {
  display: block;
}

.setting_dropdown .dropdown-submenu {
  position: relative;
}

.setting_dropdown .dropdown-submenu:hover>.dropdown-menu {
  display: block;
  top: 0;
  left: -98%;
  margin-top: -6px;
  position: absolute
}

/* rotate caret on hover */
.dropdown-menu>li>a:hover:after {
  text-decoration: underline;
  transform: rotate(-270deg);

}

.text-capitalize {
  text-transform: capitalize;

}

.my-modal {
  width: 100vw !important;
  min-width: 20vw !important;
}


/*  patient_profile_img */

.patient_profile_img {
  border: 2px solid black;
  width: 120px;
  height: 120px;
  border-radius: 50%;

}

.patient_profile_img img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
}

.contentnn {
  background-color: #ffffff !important;
  padding: 0 18px;
}

.font-14 {
  font-size: 14px;
}

/* login page css start */

.login_image {
  border-radius: 10px !important;
}

.login_page {
  /* height: calc(100vh-80px) !important; */
  height: calc(100vh - 10px);
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
  flex-direction: column;
}

.login_page h4 {
  font-family: 'Poppins', sans-serif;
}

.login_image img {
  width: 100%;
  height: 100%;
  border-radius: 10px !important;
}

.login_form {
  border-radius: 10px !important;
}

.login_form {
  display: flex;
  justify-content: center;
  align-items: center;
}

.login_form label {
  font-family: 'Poppins', sans-serif;
}

.login_button {
  display: flex;
  justify-content: center;
}

.login_contact_details {
  font-family: 'Poppins', sans-serif;
}

/* loginpage css end */

/* upload image */
.bodrerer {
  height: 120px;
  /* max-width: 300px; */
  text-align: center;
  padding: 9%;
  border-style: dashed;
  border-radius: 15px;
  position: relative;
}

.bodrerer img {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  object-fit: cover;
  border-radius: 15px;

}

.bodrerer input {
  display: none;
}

label {
  cursor: pointer;
}

.delete_icon_toggal {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.delete_icon_toggal .fab_icon {
  position: absolute;
  transform: translateX(0px);
  right: 50px;
  transition: all 0.5s;
  opacity: 0;

}

.delete_icon_toggal:hover .fab_icon {
  opacity: 1;
  right: 0px;
}

/* ==================new css ============== */
/* 
.App {
  text-align: center;
  font-family: Arial, sans-serif;
  margin: 20px;
}

.array-box {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px solid #333;
  padding: 10px;
  margin: 10px;
}

.item {
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f0f0f0;
  border: 1px solid #ccc;
  margin: 5px;
  cursor: grab;
} */


/* ArrayReorderApp.css */

/* .container {
  text-align: center;
  margin: 20px;
} */

.item-list {
  list-style: none;
  padding: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.item {
  background-color: #f0f0f0;
  border: 1px solid #ddd;
  border-radius: 5px;
  margin: 5px;
  padding: 10px;
  cursor: grab;
  user-select: none;
}

.item:active {
  cursor: grabbing;
}



/* ======================================== */


.file-container {
  width: 100px;
  height: 100px;
  position: relative;

}

.file-container img {
  width: 100%;
  height: 100%;
  border-radius: 5px;
}

.file-container button {
  position: absolute;
  margin: 0 !important;
  /* z-index: -1; */
  left: 50%;
  top: 120%;
  transform: translate(-50%, -50%);
  cursor: pointer !important;

}

.css-wmy1p7-ReactDropdownSelect {
  border: none !important;
  border-bottom: 1px solid !important;
  border-radius: 0 !important;
}

.css-wmy1p7-ReactDropdownSelect:focus,
.css-wmy1p7-ReactDropdownSelect:focus-within {
  outline: none !important;
  box-shadow: none !important;
}

.paySumcontentBox {
  font-size: 12px;
  padding: 4px;
}

.patientReportFilter {
  border-radius: 10px;
  border: 1px solid #dee2e6;
  max-width: 50%;
  margin: 0 10px;
  padding: 0 10px;
}

.patientPaymentSelect {
  padding: 0 !important;
  margin: .5rem 0 .5rem 0 !important;
}

.payment_paid {
  background: #aad3c6ad;
}

.payment_pending {
  background: #e0e1aae0;
}

.legend_payment_list {
  height: 10px;
  width: 10px;
}

.sticky-container {
  position: -webkit-sticky;
  position: sticky;
  bottom: 0;
  z-index: 999;
  background-color: white;
  /* Optional: to ensure the sticky section has a background */
  padding: 10px;
  /* Optional: for some spacing */
  border-bottom: 1px solid #ccc;
  /* Optional: to visually separate the sticky section */
}

.problem-log-selected-patient-appointments {
  min-height: 200px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 1px solid #949494;
  border-radius: 5px;
}

.problem-log-selected-patient-appointments-title {
  display: flex;
  justify-content: center;
  padding: 10px 0;
  border-bottom: 1px solid #ccced1;
  width: 100%;
}

.problem-log-selected-patient-appointments-layout {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin: 10px 0;
}

.problem-log-selected-patient-appointments-box {
  display: flex;
  flex-direction: column;
  border: 1px solid #ccced1;
  padding: 5px 10px;
  margin: 5px;
  align-items: center;
  border-radius: 5px;
  font-size: 12px;
  min-width: 130px;
  min-height: 80px;
  justify-content: space-around;
}

.problem-log-selected-patient-appointments-box {
  border: 1px solid #ddd;
  border-radius: 5px;
  background-color: #1976d245;
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.problem-log-selected-patient-appointments-box:hover {
  background-color: #f5f5f5;
}

.problem-log-selected-patient-appointments-box.faded {
  background-color: #f9f9f9;
  color: #aaa;
  cursor: pointer;
}

.problem-log-view-title {
  display: flex;
  justify-content: space-between;
  padding: 10px;
}

.problem-log-selected-patient-appointments-legend-selected,
.problem-log-selected-patient-appointments-legend-unselected {
  height: 25px;
  width: 25px;
  border: 1px solid #000;
  border-radius: 5px;
}

.problem-log-selected-patient-appointments-legend-selected {
  background: #1976d245;
}

.problem-log-selected-patient-appointments-legend-unselected {
  background: #f9f9f9;
}