body {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
  font-family: 'Roboto', sans-serif;
  background-color: #f0efef;
}

h3 {
  font-size: 15px;
}

li {
  font-size: 14px;
}

.col-2 {
  width: 12.5%;
}

.font-14px {
  font-size: 14px;
}

.font-11px {
  font-size: 12px;
}

.colorlite {
  color: rgba(128, 128, 128, 0.805);
}

.colorblue {
  color: #2F90EA;
}

.nav-link {
  color: rgba(128, 128, 128, 0.805);
  /* padding: 7px 16px !important; */
}

.activepage {
  background-color: #103a40;
  padding: 5px 20px 5px 20px;
  color: #ffffff !important;
  border-radius: 5px;
  font-weight: 600;
}

.activepage2 {
  background-color: #ffffff !important;
  padding: 5px 8px 5px 8px !important;
  color: #103a40 !important;
  border-radius: 5px !important;
  font-weight: 600 !important;
}

.btn:focus {
  outline: 0;
  box-shadow: none;
}

.bordermain {
  border: 2px solid #0a5bfe10;
  border-radius: 10px;
}

/* navbar start  */

.dashboardlist li {
  list-style: none;
  margin: 10px 25px -7px 0;
  font-weight: 600;
}

.dashboardlist a {
  text-decoration: none;
  color: rgb(0, 0, 0);
  padding: 8px 20px 8px 20px;
}

.dashboardlist a:hover {
  background-color: #103a40;
  color: #ffffff;
  border-radius: 5px;
  font-weight: 600;
}

.dashboardlist2 li {
  list-style: none;
  margin: 10px 20px 0px 0;
  font-weight: 500;
}
.dashboardlist2 li a {
  width: 100%;
  text-overflow: ellipsis !important;
  white-space: nowrap;
}

.dashboardlist2 a {
  text-decoration: none;
  color: rgb(255, 255, 255);
  padding: 8px 5px 8px 5px;
}

.dashboardlist2 a:hover {
  background-color: white;
  color: #103a40;
  border-radius: 5px;
  /* font-weight: 600; */
}

.btn-primary {
  background-color: #104DC9;
}

label {
  font-size: 13px;
}

input {
  border-radius: 5px;
  border: 1px solid rgba(128, 128, 128, 0.508);
  padding: 5px 5px;
  width: 330px;
}

.btn_login {
  width: 330px;
}

::placeholder {
  font-size: 12px;
  color: rgba(128, 128, 128, 0.70);
}

.font-11px {
  font-size: 12px;
}

.onesideborder {
  border-bottom: 1px solid rgba(128, 128, 128, 0.508) !important;
  border: none;
}

.bgimg {
  position: relative;
  text-align: center;
  color: white;
}

.menimg1 {
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 230px;
  height: 230px;
  border: 10px solid white;
  border-radius: 50%;
}

.menimg1 .profile_image {
  width: 100%;
  height: 100%;
  border-radius: 50%;
}

.menimg2 {
  position: absolute;
  bottom: -12%;
  right: 0;
  transform: translate(-50%, -50%);
}

/* .menimg3 {
  position: absolute;
  top: 100%;
  left: 50%;
} */


/* ofcanvas */
.offcanvas-end {
  top: 53px;
  right: 0;
  width: 400px;
  border-left: 1px solid rgba(0, 0, 0, .2);
  transform: translateX(100%);
}

hr {
  margin: 5px 0;
  color: inherit;
  background-color: currentColor;
  border: 0;
  opacity: .25;
}

/* ofcanvas */

/* tooltip */
.hide {
  display: none;
}

.myDIV:hover+.hide {
  display: block;
  color: #2F90EA;
  position: absolute;
  top: 35px !important;
  left: 15px;
}

/* tooltip */

/* checkbox */
.inputcheck {
  border-radius: 5px;
  border: 1px solid rgba(128, 128, 128, 0.508);
  padding: 5px 5px;
  width: auto;
}

/* checkbox */



/* img hover edit buton */
.imgoverlay {
  position: relative;
  width: 100%;
  max-width: 400px;
}

.image {
  display: block;
  width: 100%;
  height: auto;
}

.overlay {
  position: absolute;
  top: 31px;
  bottom: 0;
  left: 31px;
  right: 0;
  height: 79%;
  width: 79%;
  opacity: 0;
  transition: .3s ease;
  background-color: rgba(0, 0, 0, 0.541);
  border-radius: 50%;
}

.imgoverlay:hover .overlay {
  opacity: 1;
  color: #ffff;
}

.icons {
  color: white;
  font-size: 60px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  text-align: center;
}

.fa-plus:hover {
  color: #eee;
}

/* img hover edit buton */

@media screen and (min-width: 768px) and (max-width: 1200px) {
  h3 {
    font-size: 15px;
  }

  li {
    font-size: 12px;
  }

  .dashboardlist li {
    list-style: none;
    margin: 10px 0px -7px 0 !important;
    font-weight: 600;
  }

  .dashboardlist a {
    text-decoration: none;
    color: rgb(0, 0, 0);
    padding: 8px 20px 8px 20px;
  }

  .dashboardlist a:hover {
    background-color: #103a40;
    color: #ffffff;
    border-radius: 5px;
    font-weight: 600;
  }

  .dashboardlist2 li {
    list-style: none;
    /* margin: 10px 5px -7px 0; */
    /* font-weight: 500; */
  }

  .dashboardlist2 a {
    text-decoration: none;
    color: rgb(255, 255, 255);
    padding: 8px 5px 8px 5px;
  }

  .dashboardlist2 a:hover {
    background-color: white;
    color: #103a40;
    border-radius: 5px;
    font-weight: 600;
  }

  .btn-primary {
    background-color: #104DC9;
  }

  label {
    font-size: 13px;
  }

  input {
    border-radius: 5px;
    border: 1px solid rgba(128, 128, 128, 0.508);
    padding: 5px 5px;
    width: 330px;
  }

  .btn_login {
    width: 330px;
  }

  ::placeholder {
    font-size: 12px;
    color: rgba(128, 128, 128, 0.70);
  }

  .font-11px {
    font-size: 12px;
  }

}

/* @media screen and (min-width: 1200px) and (max-width: 1360px) {
  .dashboardlist2 ul li {
    font-size: 14px !important;
  }

} */